import React from 'react'
import PropTypes from 'prop-types'
import 'lazysizes'
import 'lazysizes/plugins/attrchange/ls.attrchange'
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks'

//styles
import {main} from './layout.module.scss'

const Layout = props => {
    return <>
        <main className={`${main} ${props.classes}`}>
            {props.children}                       
        </main>
        <noscript>
            <style>
                {`
                        [data-sal|=fade] {
                            opacity: 1;
                        }

                        [data-sal|=slide],
                        [data-sal|=zoom] {
                            opacity: 1;
                            transform: none;
                        }

                        [data-sal|=flip] {
                            transform: none;
                        }

                        .heroBg{
                            background-image: url(https://res.cloudinary.com/dqpij5loy/image/upload/c_scale,q_auto,w_2560/v1606961295/Website_Desktop_Banner_Pink_BG_3840_x_2160px_itikmp.png);
                        }

                        @media (max-width: 576px){
                            .heroBg{
                                background-image: url(https://res.cloudinary.com/dqpij5loy/image/upload/q_auto,c_scale,w_576/v1614161820/Website_Online_Banner_Pink_BG_V2_3840_x_2160px_vzorx2.png);
                            }
                        }

                        .no-js img.lazyload {
                            display: none;
                          }
                    `}
            </style>
        </noscript>
    </>
    
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.string,
    type: PropTypes.string,
    test: PropTypes.string,
    hideFooter: PropTypes.bool,
    moissanite: PropTypes.bool
}

export default Layout